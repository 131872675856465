<template>
  <div>
    <h4>
      {{ $t("applicants.max.title") }}
      <help>{{ $t("applicants.max.info") }}</help>
    </h4>
    <div class="flix-flex flix-flex-inline flix-flex-center flix-btn-group">
      <input
        class="flix-form-control flix-range-control"
        v-model="maxApplicants"
        type="number"
        step="1"
        :max="maxValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      maxApplicants: this.getDefaultApplicants(),
      maxValue: 50
    };
  },
  computed: {},
  mounted() {},
  watch: {
    maxApplicants() {
      this.checkmaxApplicants();
    }
  },
  methods: {
    setSave() {
      this.$store.commit("tableau/setUpdate");
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.max_applicants[
        this.$store.state.business.weekday
      ] = this.maxApplicants;

      if (this.$store.state.tableau.view === "week") {
        Object.keys(data.max_applicants).forEach(
          function(k) {
            data.max_applicants[k] = this.maxApplicants;
          }.bind(this)
        );
      }
      this.$store.commit("business/prefetch", data);
    },
    getDefaultApplicants() {
      if (
        typeof this.$store.state.business.unsaved.max_applicants[
          this.$store.state.business.weekday
        ] !== "undefined"
      ) {
        return this.$store.state.business.unsaved.max_applicants[
          this.$store.state.business.weekday
        ];
      }
      return 1;
    },
    changeAppointment(add) {
      this.maxApplicants = this.maxApplicants + add;
    },
    checkmaxApplicants() {
      if (this.maxApplicants < 0) {
        this.maxApplicants = 1;
      }
      if (this.maxApplicants > this.maxValue) {
        this.maxApplicants = this.maxValue;
      }
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped></style>
